<template>
<div class="Details">
   <div class="DetailsBox">
      <h1 v-html="NewDetailsDate.title"></h1>
      <h2>发布时间：{{NewDetailsDate.publish_time}}</h2>
      <div v-html="NewDetailsDate.Content" class="DetailsMain">
      </div>
   </div>
</div>
</template>

<script>
export default {
   name: "NewsPage",
   components: {},
   data() {
      return {
         NewDetailsDate: {},
      };
   },
   created() {
      if (this.$route.params.id) {
         this.axios.get(
            Global.NEWS_DETAIL_API + `${ this.$route.params.id}`
         ).then((res) => {
            this.NewDetailsDate = res.data;
            localStorage.setItem("news_page", JSON.stringify(res.data))
            console.log(this.NewDetailsDate);
         });
      } else {
         this.NewDetailsDate = JSON.parse(localStorage.getItem("news_page"))
         console.log(this.NewDetailsDate);
      }
   },
};
</script>

<style lang="scss" scoped>
.Details {
   padding-top: 159Px;
   background-color: #fff;

   /deep/ img {
      display: block;
      width: 480Px;
      height: auto;
      margin: 0 auto;
   }

   .DetailsBox {
      width: 960Px;
      margin: 0 auto;
      padding-bottom: 70Px;

      h1 {
         font-size: 28Px;
         font-weight: bold;
         color: #002060;
         margin-bottom: 10Px;
         text-align: center;
         line-height: 50Px;
      }

      h2 {
         text-align: center;
         font-size: 16Px;
         font-weight: normal;
         margin-bottom: 70Px;
      }

      /deep/ .DetailsMain {
         font-size: 18Px;
      }
   }
}
</style>
